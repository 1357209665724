import jwt from "jsonwebtoken";

export function toBool(value: any) {
	if (!value) {
		return false;
	}

	value = value.toString();
	value = value.trim();
	value = value.toLowerCase();

	const TRUTHY_VALUES = "y yes true".split(/\s/);

	if (!value.length) {
		return false;
	}
	if (!isNaN(Number(value))) {
		return value > 0;
	}
	return TRUTHY_VALUES.indexOf(value) >= 0;
}

export function parseJwt(token: string) {
	// const base64Url = token.split(".")[1];
	// const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	// const jsonPayload = decodeURIComponent(
	// 	atob(base64)
	// 		.split("")
	// 		.map(function (c) {
	// 			return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
	// 		})
	// 		.join("")
	// );

	return jwt.decode(token) as any;
}

export const regexp = {
	email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
	name: /^[a-zA-Z\s]*$/,
	password: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
	phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
};

const defaults = { nonTextBehavior: "remove" };

export const blocksToText = (blocks, opts = {}) => {
	const options = Object.assign({}, defaults, opts);
	return blocks
		.map((block) => {
			if (block._type !== "block" || !block.children) {
				return options.nonTextBehavior === "remove"
					? ""
					: `[${block._type} block]`;
			}

			return block.children.map((child) => child.text).join("");
		})
		.join("\n\n");
};

export const toSnakeCase = (input: string = ""): string => {
	return input
		.replace(/\W+/g, " ")
		.split(/ |\B(?=[A-Z])/)
		.map((word) => word.toLowerCase())
		.join("_");
};

export function objectToQueryString(obj: any) {
	if (Object.keys(obj).length === 0) {
		return ""; // Return empty string for an empty object
	}

	const queryParams = Object.entries(obj)
		.map(
			([key, value]) =>
				`${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`
		)
		.join("&");

	return `?${queryParams}`;
}
